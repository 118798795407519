import "./MainNav.css"
import { useDispatch } from "react-redux"
import {exit, reset } from "../animationController"

export default function MainNav({currentScreen, handleNext}){
    const dispatch = useDispatch()
    const layoutMap = {
        greeting: {
            name: "greeting",
            index: 0,
            zoom: "",
            delay: 1000
        },

        skills: {
            name: "skills",
            index: 1,
            zoom: "bg-zoom-left",
            delay: 1000
        },

        projects: {
            name: "projects",
            index: 2,
            zoom: 'bg-zoom-mid-left',
            delay: 1000
        },

        contact: {
            name: "contact",
            index: 3,
            zoom: 'bg-zoom-mid-right',
            delay: 1000
        }
    }

    function handleClick(e){
        const destination = e.target.innerText === "Home" ? "greeting" : e.target.innerText.toLowerCase()
        if(destination === currentScreen) return
        let newFlow = "forward"
        if(layoutMap[destination].index < layoutMap[currentScreen].index){
            newFlow = "backward"
        } 

        handleNext(layoutMap[destination].zoom, destination, layoutMap[destination].delay)
        switch(currentScreen){
            case "greeting": 
                dispatch(exit({key: "greetingShouldExit", flow: newFlow}))
                setTimeout(() =>{
                    dispatch(reset({key: "greetingShouldExit"}))
                }, 1000)
                break;
            case "skills":
                dispatch(exit({key: "skillsShouldExit", flow: newFlow}))
                setTimeout(() =>{
                    dispatch(reset({key: "skillsShouldExit"}))
                }, 1000)
                break;
            case "projects":
                dispatch(exit({key: "projectsShouldExit", flow: newFlow}))
                setTimeout(() =>{
                    dispatch(reset({key: "projectsShouldExit"}))
                }, 1000)
                break;
            case "contact": 
            dispatch(exit({key: "contactShouldExit", flow: newFlow}))
            setTimeout(() =>{
                dispatch(reset({key: "contactShouldExit"}))
            }, 1000)
                break
            default:
                break

        }
    }

    return(
        <nav className="main-nav">
            <button onClick={handleClick} className={`nav-item ${currentScreen === "greeting" && "current"}`}>Home</button>
            <button onClick={handleClick} className={`nav-item ${currentScreen === "skills" && "current"}`}>Skills</button>
            <button onClick={handleClick} className={`nav-item ${currentScreen === "projects" && "current"}`}>Projects</button>
            <button onClick={handleClick} className={`nav-item ${currentScreen === "contact" && "current"}`}>Contact</button>
        </nav>
    )

}