import "./ProjectSlide.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
function ProjectSlide({img, title, bullets, description, clickLeft, clickRight, url}){
    return (
        <div className="project-slide">
            <div className="project-slide-image">
                <img src={img} alt={title}/>
                <div className="project-slide-buttons">
                <div onClick={clickLeft}><FontAwesomeIcon icon={faChevronLeft}/></div>
                <div onClick={clickRight}><FontAwesomeIcon icon={faChevronRight}/></div>
            </div>
            </div>
            <div className="project-slide-content">
                <p>{description}</p>
                {url !== "" ? <button className="visit-btn" ><a href={url}>App Store</a></button> : null}
            </div>
            
        </div>
                    
    )
}

export default ProjectSlide