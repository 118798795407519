import { configureStore } from "@reduxjs/toolkit";
import animationController from "./animationController";


export default configureStore({
    reducer: {
        animations: animationController,
        
    },
    devTools: false
})