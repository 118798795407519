// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skill-card-heading {
    margin: 5% 10% 3% 20%;
    color: white;
    transition: all 2s;
    flex-basis: 25%;
}

.skill-card-content {
    margin: 3% 5% 3% 5%;
    color: white;
    flex-basis: 35%;
    text-align: center;
    align-items: center;
    justify-content: center;
    transition: all 2s;
}


.flip-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}


@media screen and (max-width: 1600px) {
    .skill-card-content {
        flex-basis: 45%;
    }

}

@media screen and (max-width: 500px) {
    .flip-card-container{
        /* display: block;  */
        overflow: scroll;
        height: 100%;
        width: 100%;
    }

    .skill-card-content {
        margin:  2% 2% 0% 2%;
    }
}



    



`, "",{"version":3,"sources":["webpack://./src/skills/Skills.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;;AAGA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;AACvB;;;AAGA;IACI;QACI,eAAe;IACnB;;AAEJ;;AAEA;IACI;QACI,qBAAqB;QACrB,gBAAgB;QAChB,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".skill-card-heading {\n    margin: 5% 10% 3% 20%;\n    color: white;\n    transition: all 2s;\n    flex-basis: 25%;\n}\n\n.skill-card-content {\n    margin: 3% 5% 3% 5%;\n    color: white;\n    flex-basis: 35%;\n    text-align: center;\n    align-items: center;\n    justify-content: center;\n    transition: all 2s;\n}\n\n\n.flip-card-container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: center;\n}\n\n\n@media screen and (max-width: 1600px) {\n    .skill-card-content {\n        flex-basis: 45%;\n    }\n\n}\n\n@media screen and (max-width: 500px) {\n    .flip-card-container{\n        /* display: block;  */\n        overflow: scroll;\n        height: 100%;\n        width: 100%;\n    }\n\n    .skill-card-content {\n        margin:  2% 2% 0% 2%;\n    }\n}\n\n\n\n    \n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
