// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-card-content {
    flex-basis: 50%;
    margin: 5% 0% 0% 10%;
}


.project-card-heading {
    margin: 5% 5% 0% 10%;
    color: white;
    transition: all 2s;
    flex-basis: 50%;
}

@media screen and (max-width: 1000px) {
    .project-card-heading {
        margin: 0% 0% 0% 0%;
    }
}
    
`, "",{"version":3,"sources":["webpack://./src/projects/Projects.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,oBAAoB;AACxB;;;AAGA;IACI,oBAAoB;IACpB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".project-card-content {\n    flex-basis: 50%;\n    margin: 5% 0% 0% 10%;\n}\n\n\n.project-card-heading {\n    margin: 5% 5% 0% 10%;\n    color: white;\n    transition: all 2s;\n    flex-basis: 50%;\n}\n\n@media screen and (max-width: 1000px) {\n    .project-card-heading {\n        margin: 0% 0% 0% 0%;\n    }\n}\n    \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
