import {useState} from "react"
import MessageBox from "../ui/MessageBox"
import "./Projects.css"
import ProjectPlaceholder from "../images/project-placeholder.png"
import ProjectSlide from "./ProjectSlide"
import OptimalFlyer from "../images/optimal-graphic-large.png"
import OptimizeBanner from "../images/of_banner.jpg"
import { useDispatch, useSelector } from "react-redux"
import { exit, reset } from "../animationController"


function Projects({handleClick,hasVisited, setHasVisited}){
    const dispatch = useDispatch()
    const shouldExit = useSelector(state => state.animations.projectsShouldExit)
    const direction = useSelector(state => state.animations.flow)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [slides, setSlides] = useState([
        {
            title: "Project 1",
            description: "Optimize Fitness is an interactive workout log for iOS.",
            bullets: ["bullet 1", "bullet 2", "bullet 3"],
            image: OptimizeBanner, 
            url: "https://apps.apple.com/us/app/optimize-fitness/id6472675277"
        }, {
            title: "Coming Soon",
            description: "Top Secret Project",
            bullets: ["bullet 1", "bullet 2", "bullet 3"],
            image: ProjectPlaceholder,
            url: ""
        }])

    

    const messagesObj = {
        first: "Whew! All this zooming around is making me tired!",
        second: "Its a good thing I'm not a real person, or I'd be exhausted!",
        third: "Here, you can see some of my projects.",
        fourth: "I'm always working on something new, so check back often to see what I'm up to!"
    }

    const visitedMessage ={
        first: "I'm always working on something new, so check back often to see what I'm up to!",
    }

    function handleNext(){
        dispatch(exit({key: "projectsShouldExit", flow: "forward"}))
        setTimeout(()=> {
            dispatch(reset({key: "projectsShouldExit"}))
        }, 1500)
        setHasVisited(true)
        handleClick('bg-zoom-mid-right', 'contact', 1000)
    }

    function handleBack(){
        dispatch(exit({key: "projectsShouldExit", flow: "backward"}))
        setTimeout(()=> {
            dispatch(reset({key: "projectsShouldExit"}))
        }, 1500)
        handleClick('bg-zoom-left', 'skills', 1000)
    }

    function parseAnimationClass(){
        if(shouldExit && direction === "forward") {
            return "fade-out-left-zoom-out"
        } else if (shouldExit && direction === "backward"){
            return "fade-out-right-zoom-out"
        } else if(direction === "forward") {
            return "fade-in-left-zoom-in"
        } else {
            return "fade-in-right-zoom-in"
        }
    }

    
    return (
        <div className={`wrapper ${parseAnimationClass()}`}>
            <div className="project-card-heading">
                <ProjectSlide img={slides[currentSlide].image} title={slides[currentSlide].title} bullets={slides[currentSlide].bullets} description={slides[currentSlide].description} url={slides[currentSlide].url}
                clickLeft={() => setCurrentSlide(currentSlide === 0 ? slides.length -1 : currentSlide - 1)} clickRight={() => setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1)}/>
            </div>
            <MessageBox messagesObj={!hasVisited ? messagesObj : visitedMessage}  handleExit={handleNext} handleBack={handleBack}/>
        </div>
        
    )
}

export default Projects