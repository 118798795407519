// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-heading{
    margin: 15% 10% 10% 20%;
    color: white;
    flex-basis: 35%;
}

.card-content {
    margin: 5% 5% 3% 0%;
    color: white;
    background-color: #121212b4;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #1e9bff;
    box-shadow: 0 0 10px #1e9bff, 0 0 20px #1e9bff, 0 0 30px #1e9bff;
    flex-basis: 35%;
    height: 60vh;
}


.card-content p {
    font-size: 1.5rem;
    margin: 20px 0px;
    color: #13A10E;
    font-family: 'Cascadia Mono', monospace;
    text-align: left;
}

.card-content .caret {
    font-size: 2rem;
}

@media screen and (max-width: 1000px) {

    .card-heading{
        margin: 25% 10% 10% 10%;
    }

}
    

    


    `, "",{"version":3,"sources":["webpack://./src/greeting/Greeting.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,gEAAgE;IAChE,eAAe;IACf,YAAY;AAChB;;;AAGA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,uCAAuC;IACvC,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;;IAEI;QACI,uBAAuB;IAC3B;;AAEJ","sourcesContent":[".card-heading{\n    margin: 15% 10% 10% 20%;\n    color: white;\n    flex-basis: 35%;\n}\n\n.card-content {\n    margin: 5% 5% 3% 0%;\n    color: white;\n    background-color: #121212b4;\n    padding: 20px;\n    border-radius: 10px;\n    border: 2px solid #1e9bff;\n    box-shadow: 0 0 10px #1e9bff, 0 0 20px #1e9bff, 0 0 30px #1e9bff;\n    flex-basis: 35%;\n    height: 60vh;\n}\n\n\n.card-content p {\n    font-size: 1.5rem;\n    margin: 20px 0px;\n    color: #13A10E;\n    font-family: 'Cascadia Mono', monospace;\n    text-align: left;\n}\n\n.card-content .caret {\n    font-size: 2rem;\n}\n\n@media screen and (max-width: 1000px) {\n\n    .card-heading{\n        margin: 25% 10% 10% 10%;\n    }\n\n}\n    \n\n    \n\n\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
