import { createSlice } from "@reduxjs/toolkit";
import { reservationsUrl } from "twilio/lib/jwt/taskrouter/util";


const animationSlice = createSlice({
    name: "animations",
    initialState: {
        greetingShouldExit: false,
        skillsShouldExit: false,
        projectsShouldExit: false,
        contactShouldExit: false,
        flow: "foward"
    },

    reducers: {
        exit(state, action){
           const {key, flow} = action.payload
           state[key] = true
           state.flow = flow
        },

        reset(state, action) {
            const {key} = action.payload
            state[key] = false
        },

        
    }
})

export default animationSlice.reducer
export const {exit, reset} = animationSlice.actions