import "./Home.css"
import {useState} from "react"
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import Projects from "./projects/Projects";
import Contact from "./contact/Contact";
import MainNav from "./ui/MainNav";

function Home() {
    const [current, setCurrent] = useState("greeting")
    const [bgOrientation, setBgOrientation] = useState("")
    const [greetingVisited, setGreetingVisited] = useState(false)
    const [skillsVisited, setSkillsVisited] = useState(false)
    const [projectsVisited, setProjectsVisited] = useState(false)
    const [contactVisited, setContactVisited] = useState(false)


    function handleNext(bgZoom, next, delay){
        setBgOrientation(bgZoom)
        setTimeout(()=> {
            setCurrent(next)
        }
        , delay)
        
    }


    return (
        <div className={`content ${bgOrientation}`}>
            {window.innerWidth > 1000 && (<MainNav 
                currentScreen={current} 
                handleNext={handleNext} 
                />)}
            
            {current === "greeting" && (
            <Greeting 
                handleClick={handleNext} 
                hasVisited={greetingVisited} 
                setHasVisited={setGreetingVisited} 
            />
            )}
            {current === "skills" && (
                 <Skills 
                    handleClick={handleNext} 
                    hasVisited={skillsVisited} 
                    setHasVisited={setSkillsVisited}
                    /> 
            )}
            {current === "projects" && (
                 <Projects 
                    handleClick={handleNext} 
                    hasVisited={projectsVisited} 
                    setHasVisited={setProjectsVisited}
                    /> 
            )}
            {current === "contact" && (
                 <Contact 
                 handleClick={handleNext} 
                 hasVisited={contactVisited} 
                 />  
            )}
        </div>
    );
}

export default Home;