import "./WarmButton.css"
function WarmButton({text, onClick}){


    return(
        
        <button className="button-warm" onClick={onClick}>
          {text}
        </button>
    )
}

export default WarmButton