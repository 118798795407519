// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    display: flex;
}

button {
    border: none;
    background-color: transparent;
    color: white;
    font-size: 1.25rem;
    margin: 10px;
}

button:hover {
    color: #1e9bff;
}

.current {
    color: #1e9bff;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/ui/MainNav.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,0BAA0B;AAC9B","sourcesContent":["nav {\n    display: flex;\n}\n\nbutton {\n    border: none;\n    background-color: transparent;\n    color: white;\n    font-size: 1.25rem;\n    margin: 10px;\n}\n\nbutton:hover {\n    color: #1e9bff;\n}\n\n.current {\n    color: #1e9bff;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
