import MessageBox from "../ui/MessageBox"
import ContactForm from "./ContactForm"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Contact.css"
import { useDispatch, useSelector } from "react-redux";
import {exit, reset} from "../animationController"


function Contact({handleClick,  hasVisited, }){
    const dispatch = useDispatch()
    const direction =useSelector(state => state.animations.flow)
    const messagesObj = {
        first: "Well Friend, we've reached the end of our journey.",
        second: "I hope you enjoyed learning a little bit about me.",
        third: "If you'd like to get in touch, please feel free to send me a message using this form.",
    }

    const visitedMessage ={
        first: "If you'd like to get in touch, please feel free to send me a message using this form.",
    }


    function handleBack(){
        dispatch(exit({key: "contactShouldExit", flow: "backward"}))
        setTimeout(()=> {
            dispatch(reset({key: "contactShouldExit"}))
        }, 1500)
        handleClick('bg-zoom-mid-left', 'projects', 800)
    }

    function parseAnimationClass(){
        if(direction === "backward") {
            return "fade-out-left-zoom-in"
        } else {
            return "fade-in-left-zoom-out"
        }
    }

    function handleSubmit(e, formData){
        e.preventDefault()
        fetch("https://personal-site-backend-cb8e3fd585dc.herokuapp.com/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData)
        }).then(res => res.json()).then(data => {
            if(data.success) {
                toast.success("Message Sent!", {
                    position: "top-right",
                    theme: "dark",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                    });
            } else {
                toast.error("Message Failed to Send! The owner forgot to pay for his twilio subscription. I will let him know.", {
                    position: "top-right",
                    theme: "dark",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                    });
                console.log(data)

            }
        }
            )

    }



    
    return (
        <div className={`wrapper ${parseAnimationClass()}`}>
                <MessageBox messagesObj={!hasVisited ? messagesObj : visitedMessage} handleBack={handleBack}/>
                <div className="skill-card-content">
                <ToastContainer />
                <ContactForm handleSubmit={handleSubmit}/>
                </div>
        </div>
        
    )
}

export default Contact