import FlipCard from "./FlipCard"
import MessageBox from "../ui/MessageBox"
import "./Skills.css"
import { useDispatch, useSelector } from "react-redux"
import {exit, reset} from "../animationController"


function Skills({handleClick, hasVisited, setHasVisited}){
    const dispatch = useDispatch()
    const shouldExit = useSelector(state => state.animations.skillsShouldExit)
    const direction = useSelector(state => state.animations.flow)
    const messagesObj = {
        first: "This is the first stop on our journey.",
        second: "Here we will learn a little bit about my skills and experience.",
        third: "I have a wide range of skills, from software development,  to sales and management.",
        fourth: "Flip each card to learn more. When you're ready, click the button below to move on."

    }

    const visitedMessage ={
        first: "Flip each card to learn more. When you're ready, click the button below to move on.",
    }


    function handleNext(){
        dispatch(exit({key: "skillsShouldExit", flow: "forward"}))
        setTimeout(()=> {
            dispatch(reset({key: "skillsShouldExit"}))
        }, 1500)
        setHasVisited(true)
        handleClick('bg-zoom-mid-left', 'projects', 1000)
    }

    function handleBack(){
        dispatch(exit({key: "skillsShouldExit", flow: "backward"}))
        setTimeout(()=> {
            dispatch(reset({key: "skillsShouldExit"}))
        }, 1500)
        handleClick('', 'greeting', 1500)
    }

    function parseAnimationClass(){
        if(shouldExit && direction === "forward") {
            return "fade-out-right-zoom-in"
        } else if (shouldExit && direction === "backward"){
            return "fade-out-left-big-zoom-out"
        } else if(direction === "forward") {
            return "fade-in-right-zoom-in"
        } else {
            return "fade-in-right-zoom-out"
        }
    }

    
    return (
        <div className={`wrapper ${parseAnimationClass()}`}>
        {/* <div className="card-heading">
                <MessageBox messagesObj={messagesObj} nextText={"Projects"} handleExit={handleNext} handleBack={handleBack}/>
        </div> */}
        <MessageBox messagesObj={!hasVisited ? messagesObj : visitedMessage} nextText={"Projects"} handleExit={handleNext} handleBack={handleBack}/>
            <div className="skill-card-content">
                <div className="flip-card-container">
                <FlipCard front={"Front End Development"} bullets={["HTML", "CSS", "JavaScript", "React"]} back={"I have a passion for creating beautiful user experiences with several years of experience working with the basics(HTML/CSS/JS) as well as some of the more popular frameworks such as React and Angular this website is made using React!"}/>
              <FlipCard front={"Back End Development"} bullets={["Node.js", "Express.js", "Python", "PostgreSQL"]} back={"In addition to my front end knowledge, I have strong knowledge with Node.js, Express.js, Python, Flask, and PostgreSQL"}/>
                <FlipCard front={"Sales"} bullets={["Menu Selling", "High-Line Sales", "Objection-Handling", "Negotiation"]} back={"Prior to my interest in software development, I was a highly skilled sales professional with a proven track record of success in a variety of roles from working as a sales consultant at BMW and Porsche, to working as a Financial Services Manager"}/>
                <FlipCard front={"Leadership"} bullets={["Team Building", "Mentoring", "Conflict Resolution", "Public Speaking"]} back={"With over 5 years of management experience, My main priority as a leader is to motivate, mentor and enable every member of my team to reach their full potential through positive reinforcement."}/>
                </div>
            </div>
            
        </div>
        
    )
}

export default Skills