import "./CoolButton.css"
function CoolButton({text, onClick}){


    return(
        
        <button className="button" onClick={onClick}>
          {text}
        </button>
    )
}

export default CoolButton