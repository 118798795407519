import Home from './Home';
import { Provider } from 'react-redux';
import store from "./store"
import './App.css';


function App() {
return (
    <div className="App" >
      <Provider store={store}>
        <Home/>
      </Provider>
    </div>
  );
}

export default App;
