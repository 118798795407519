// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flip-card {
    background-color: #121212b4;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #1e9bff;
    flex-basis: 45%;
    height: 265px;
    margin: 0px 0px 20px 0px;
}

.flip-card:hover {
    transform: scale(1.05);
    transition: all 0.5s ease-in-out;

}

/* .flip-card-front li {
    list-style: none;
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
    font-style: italic;
}

.flip-card-front ul {
    padding-left: 0px;
    margin-top: 20px;
} */


.flip-card-front h3 {
    color: #1e9bff;
}

@media screen and (max-width: 1000px) {s
    .flip-card {
        height:250px;
    }
}

@media screen and (max-width: 500px) {
    .flip-card {
        padding: 5px;
        margin: 2% 0%;
        flex-basis: 49%;
        height: 230px;
        overflow-y: scroll;
    }
    .flip-card:hover {
        transform: scale(1);
    }

    /* .bullets {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    } */

    .bullets p {
        /* flex-basis: 45%; */
        margin: 7px;
    }

}
    
`, "",{"version":3,"sources":["webpack://./src/skills/FlipCard.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;IACtB,gCAAgC;;AAEpC;;AAEA;;;;;;;;;;;GAWG;;;AAGH;IACI,cAAc;AAClB;;AAEA,uCAAuC;;QAE/B,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;QACb,eAAe;QACf,aAAa;QACb,kBAAkB;IACtB;IACA;QACI,mBAAmB;IACvB;;IAEA;;;;OAIG;;IAEH;QACI,qBAAqB;QACrB,WAAW;IACf;;AAEJ","sourcesContent":[".flip-card {\n    background-color: #121212b4;\n    padding: 20px;\n    border-radius: 10px;\n    border: 2px solid #1e9bff;\n    flex-basis: 45%;\n    height: 265px;\n    margin: 0px 0px 20px 0px;\n}\n\n.flip-card:hover {\n    transform: scale(1.05);\n    transition: all 0.5s ease-in-out;\n\n}\n\n/* .flip-card-front li {\n    list-style: none;\n    color: white;\n    font-weight: bold;\n    margin-bottom: 10px;\n    font-style: italic;\n}\n\n.flip-card-front ul {\n    padding-left: 0px;\n    margin-top: 20px;\n} */\n\n\n.flip-card-front h3 {\n    color: #1e9bff;\n}\n\n@media screen and (max-width: 1000px) {s\n    .flip-card {\n        height:250px;\n    }\n}\n\n@media screen and (max-width: 500px) {\n    .flip-card {\n        padding: 5px;\n        margin: 2% 0%;\n        flex-basis: 49%;\n        height: 230px;\n        overflow-y: scroll;\n    }\n    .flip-card:hover {\n        transform: scale(1);\n    }\n\n    /* .bullets {\n        display: flex;\n        flex-wrap: wrap;\n        justify-content: space-between;\n    } */\n\n    .bullets p {\n        /* flex-basis: 45%; */\n        margin: 7px;\n    }\n\n}\n    \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
