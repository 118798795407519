import {useState} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import "./ContactForm.css"

function ContactForm({handleSubmit}){
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    })

    function handleChange(e){
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }


    return (
        <form onSubmit={(e) => handleSubmit(e, formData)}>
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input onChange={handleChange} type="text" name="name" id="name" value={formData.name} className="form-control" required/>
            </div>
            <div className="form-group">
                <label htmlFor="email">Email</label>
                <input onChange={handleChange} type="text" name="email" id="email" value={formData.email} className="form-control" required/>
            </div>
            <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea onChange={handleChange} name="message" id="message" value={formData.message} className="form-control" required/>
            </div>
            <div className="socials">
                <a href="https://www.instagram.com/aeydesai/?hl=en" className="social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                <a href="https://www.linkedin.com/in/robert-harrington-226454230/" className="social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin}/></a>
            </div>
            <button className="button">Submit</button>
        </form>
    )
}

export default ContactForm