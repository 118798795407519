import {useState} from "react"
import "./Greeting.css"
import MessageBox from "../ui/MessageBox"
import { useDispatch, useSelector } from "react-redux"
import {exit, reset} from "../animationController"

function Greeting({handleClick, hasVisited, setHasVisited }){
    const[alldone, setAlldone] = useState(false)
    const shouldExit = useSelector(state => state.animations.greetingShouldExit)
    const dispatch = useDispatch()

    const messages = {
        first: "Hi, I'm Scott!",
        second: "Sorry for the wait",
        third: "It's been a while since I've had visitors",
        fourth: "Let me show you around!",
        fifth: "Click below when you are ready to journey on!",
    }

    const visitedMessage ={
        first: "Click below when you are ready to journey on!",
    }

    function handleExit(){
        dispatch(exit({key: "greetingShouldExit", flow: "forward"}))
        setHasVisited(true)
        handleClick('bg-zoom-left', 'skills', 1000)
        setTimeout(()=> {
            dispatch(reset({key: "greetingShouldExit"}))
        }, 1000)
    }
    
    return (
        <div className={shouldExit ? "wrapper fade-out-left-zoom-in" : "wrapper fade-in-left-zoom-out"}>
        <div className="card-heading">
        <h1>"If you set your goals ridiculously high and it's a failure, you will fail above everyone else's success"</h1>
            </div>
            <MessageBox messagesObj={hasVisited ? visitedMessage : messages} nextText="Begin" alldone={alldone} handleExit={handleExit}/>
        </div>
        
    )
}

export default Greeting