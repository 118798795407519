import {useState, useEffect, useRef} from "react"
import CoolButton from "./CoolButton"
import WarmButton from "./WarmButton"
import glitch from "../images/glitch.gif"
import "./MessageBox.css"


function MessageBox({messagesObj, nextText="Continue", handleExit = null, handleBack = null})
{
    const [text, setText] = useState("")
    const [done, setDone] = useState(false)
    const [idx, setIdx] = useState(0)
    const [objIndex, setObjIndex] = useState(0) 
    const[alldone, setAlldone] = useState(true)
    const canGoBack = handleBack !== null
    const canContinue = handleExit !== null
    const messageKeys = Object.keys(messagesObj)
    const messageContainer = useRef(null)

    const scrollToBottom = () => {
        messageContainer.current.scrollTop = messageContainer.current.scrollHeight

        }

    

    useEffect(() => {
        scrollToBottom()
        if (idx < messagesObj[messageKeys[objIndex]]?.length && !done) {
            if(text === "" && objIndex === 0){
                setTimeout(() => {
                    setText(text + messagesObj[messageKeys[objIndex]][idx])
                    setIdx(idx + 1)
                }, 4000)} else {
                setTimeout(() => {
                    setText(text + messagesObj[messageKeys[objIndex]][idx])
                    setIdx(idx + 1)
                }, Math.random() * 75 + 10)
            }
        } else if(!done) {
            setTimeout(() => {
                setDone(true)
            }, 2000)
        } else {
            if(objIndex < messageKeys.length - 1){
                setText("")
                setTimeout(() => {
                setObjIndex(objIndex + 1)
                setDone(false)
                setIdx(0)
                }, 700)
            } else {
                setAlldone(true)
            }
            }
    }, [idx, text, done])

    return (
        <div className="message-card">
            <div className="message-card-group">
                <div className="message-card-heading">
                    <div className="glitch-container">

                        <img className="glitch" src={glitch}/>
                    </div>
                 </div>
                <div ref={messageContainer} className="message-card-content">
                    <p>{text}<span className='caret'>_</span></p>
                </div>
            </div>
            <div className="message-card-buttons">
                        {alldone  && canContinue? <CoolButton text={nextText} onClick={handleExit}/> : null}
                        {canGoBack && alldone ? <WarmButton text={"Back"} onClick={handleBack}/> : null}
            </div>

        </div>
    )


}

export default MessageBox