import { useState } from "react"
import "./FlipCard.css"
function FlipCard({front, back, bullets}) {
  const [isFlipped, setIsFlipped] = useState(false)
  const flip = () => setIsFlipped(!isFlipped)
  return (
    <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} onClick={flip}>
      <div className="flip-card-inner">
        {!isFlipped ? (<div className="flip-card-front">
          <h3>{front}</h3>
          <div className="bullets">
           {bullets.map((bullet, index) => <p key={index}>{bullet}</p>)}
          </div>

        </div>) : (
            <div className="flip-card-back">
            {back}
          </div>
        )}
        
        
      </div>
    </div>
  )
}

export default FlipCard