// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-warm {
    position: relative;
    padding: 16px 30px;
    font-size: 1.25rem;
    color: #ff1867;
    border-radius: 4px;
    text-shadow: 0 0 15px #ff1867;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    transition: 0.5s;
    z-index: 1;
    animation: fadein 2s ease-in-out forwards;
    border: none;
    background-color: transparent;
  }
  
  .button-warm:hover {
    color: #fff;
    box-shadow: 0 0 0px #ff1867;
  }
  
  .button-warm::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff1867;
    z-index: -1;
    transform: scale(0);
    transition: 0.5s;
  }
  
  .button-warm:hover::before {
    transform: scale(1);
    transition-delay: 0.25s;
    box-shadow: 0 0 10px #ff1867,
      0 0 30px #ff1867,
      0 0 60px #ff1867;
  }
  `, "",{"version":3,"sources":["webpack://./src/ui/WarmButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,6BAA6B;IAC7B,yBAAyB;IACzB,sBAAsB;IACtB,gBAAgB;IAChB,UAAU;IACV,yCAAyC;IACzC,YAAY;IACZ,6BAA6B;EAC/B;;EAEA;IACE,WAAW;IACX,2BAA2B;EAC7B;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,uBAAuB;IACvB;;sBAEkB;EACpB","sourcesContent":[".button-warm {\n    position: relative;\n    padding: 16px 30px;\n    font-size: 1.25rem;\n    color: #ff1867;\n    border-radius: 4px;\n    text-shadow: 0 0 15px #ff1867;\n    text-transform: uppercase;\n    letter-spacing: 0.1rem;\n    transition: 0.5s;\n    z-index: 1;\n    animation: fadein 2s ease-in-out forwards;\n    border: none;\n    background-color: transparent;\n  }\n  \n  .button-warm:hover {\n    color: #fff;\n    box-shadow: 0 0 0px #ff1867;\n  }\n  \n  .button-warm::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: #ff1867;\n    z-index: -1;\n    transform: scale(0);\n    transition: 0.5s;\n  }\n  \n  .button-warm:hover::before {\n    transform: scale(1);\n    transition-delay: 0.25s;\n    box-shadow: 0 0 10px #ff1867,\n      0 0 30px #ff1867,\n      0 0 60px #ff1867;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
