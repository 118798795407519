// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
    background-color: #121212b4;
    border-radius: 10px;
    border: 2px solid #1e9bff;
    padding: 20px;
    margin-top: 5%;
}

label {
    color: white;
    font-size: 1.5rem;
    font-family: 'Cascadia Mono', monospace;
}

input[type=text], input[type=text]:focus {
    background-color: #121212;
    color: #1e9bff;
    text-shadow: 0 0 15px #1e9bff;
    border: 1px solid #1e9bff;
}

input[type=text]:focus {
    box-shadow: 0 0 10px #1e9bff, 0 0 20px #1e9bff, 0 0 30px #1e9bff;
}

#message {
    background-color: #121212;
    color: #1e9bff;
    height: 200px;
    border: 1px solid #1e9bff;
}

#message:focus {
    box-shadow: 0 0 10px #1e9bff, 0 0 20px #1e9bff, 0 0 30px #1e9bff;
}

.socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}

.social-link{
    margin: 0px 25px;
    text-decoration: none;
    color: #1e9bff;
    font-size: 30px;
}

.social-link svg:hover{
    transform: scale(1.25);
}

@media screen and (max-width: 1000px) {
    form {
        /* margin: 10% 2% 5% 2%; */
        width: 100%;
    }  

    #message {
        height: 100px;
    }
}`, "",{"version":3,"sources":["webpack://./src/contact/ContactForm.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,uCAAuC;AAC3C;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,6BAA6B;IAC7B,yBAAyB;AAC7B;;AAEA;IACI,gEAAgE;AACpE;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gEAAgE;AACpE;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI;QACI,0BAA0B;QAC1B,WAAW;IACf;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":["form {\n    background-color: #121212b4;\n    border-radius: 10px;\n    border: 2px solid #1e9bff;\n    padding: 20px;\n    margin-top: 5%;\n}\n\nlabel {\n    color: white;\n    font-size: 1.5rem;\n    font-family: 'Cascadia Mono', monospace;\n}\n\ninput[type=text], input[type=text]:focus {\n    background-color: #121212;\n    color: #1e9bff;\n    text-shadow: 0 0 15px #1e9bff;\n    border: 1px solid #1e9bff;\n}\n\ninput[type=text]:focus {\n    box-shadow: 0 0 10px #1e9bff, 0 0 20px #1e9bff, 0 0 30px #1e9bff;\n}\n\n#message {\n    background-color: #121212;\n    color: #1e9bff;\n    height: 200px;\n    border: 1px solid #1e9bff;\n}\n\n#message:focus {\n    box-shadow: 0 0 10px #1e9bff, 0 0 20px #1e9bff, 0 0 30px #1e9bff;\n}\n\n.socials {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 10px 0px;\n}\n\n.social-link{\n    margin: 0px 25px;\n    text-decoration: none;\n    color: #1e9bff;\n    font-size: 30px;\n}\n\n.social-link svg:hover{\n    transform: scale(1.25);\n}\n\n@media screen and (max-width: 1000px) {\n    form {\n        /* margin: 10% 2% 5% 2%; */\n        width: 100%;\n    }  \n\n    #message {\n        height: 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
